import React from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import { InfoRounded as InfoRoundedIcon } from "@material-ui/icons";
import SearchField from "components/SearchField/SearchField";
import useStyles from "./SidebarHeader.style";
import { filterSurah } from "redux/actions/filterSurah";
import { IconButton, Dialog, DialogContent, DialogContentText, Tooltip, Typography } from "@material-ui/core";

const SidebarHeader = ({ collapsed, filterSurah }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      filterSurah(value);
    }, 300);

    return() => {
      clearTimeout(timeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <div
        className={classNames(classes.iconContainer, {
          [classes.iconContainerCollapsed]: collapsed
        })}
      >
        <Typography variant='h6' color='primary'>
          Qur'an Time
        </Typography>
        <div className={classes.grow} />
        <Tooltip title="About" interactive>
          <IconButton
            color="primary"
            size="small"
            aria-label="about app"
            component="span"
            onClick={() => setOpen(true)}
          >
            <InfoRoundedIcon />
          </IconButton>
        </Tooltip>
        <div style={{ paddingBottom: 16 }} />
      </div>
      <SearchField onChange={value => setValue(value)} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quran Time ver. 1.0.0
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  filterSurah: value => dispatch(filterSurah(value))
})

export default connect(null, mapDispatchToProps)(React.memo(SidebarHeader));
