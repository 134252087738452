import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => {
const { breakpoints, palette } = theme;
return({
    bottomAppBar: {
        top: 'auto',
        bottom: 0,
        [breakpoints.up('md')]: {
            marginLeft: 256,
            width: 'calc(100% - 256px)'
        }
    },
    bottomToolbar: {
        justifyContent: 'center',
        backgroundColor: palette.background.paper
    },
    buttonController: {
        padding: 2,
        textAlign: 'center'
    }
  })
});

  export default useStyles;
