import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { onNextAyah, onPrevAyah, toggleTranslation, setAyah } from 'redux/actions/ayahNav';
import { AppBar, Toolbar, IconButton, Snackbar, Tooltip, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
    TranslateRounded as TranslateRoundedIcon,
    ArrowBackRounded as ArrowBackRoundedIcon,
    ArrowForwardRounded as ArrowForwardRoundedIcon,
    FilterNoneRounded as FilterNoneRoundedIcon,
} from '@material-ui/icons';
import useStyles from './BottomAppBar.style';
import bodyContentStyles from '../BodyContent/BodyContent.style';
 
let timeout = null;
const BottomAppBar = ({ onNextAyah, onPrevAyah, setAyah, ayahOrder, dataSurah, isTranslate, toggleTranslation }) => {
    const classes = useStyles();
    const bodyClasses = bodyContentStyles();
    const [snackbar, setSnackbar] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [ayahNumber, setAyahNumber] = React.useState(1);

    React.useEffect(() => {
      setAyahNumber(ayahOrder);
    }, [ayahOrder]);

    return (
      <>
        <AppBar
            position="fixed"
            color="default"
            elevation={0}
            className={classes.bottomAppBar}
          >
            <Toolbar classes={{ root: classes.bottomToolbar }}>
              <div className={classes.buttonController}>
                <Tooltip title={`${isTranslate ? 'Hide' : 'Show'} Translation`} interactive>
                  <IconButton
                    color={isTranslate ? 'primary' : 'default'}
                    aria-label="upload picture"
                    component="span"
                    onClick={toggleTranslation}
                    disabled={document.getElementsByClassName(bodyClasses.ayah).length === 0}
                  >
                    <TranslateRoundedIcon />
                  </IconButton>
                </Tooltip>
              </div>

              {/* PREV AND NEXT PAGE AYAH NAVIGATION */}
              <div className={classes.buttonController}>
                <Tooltip title="Next Ayah" interactive>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={onNextAyah}
                    disabled={ayahOrder === (dataSurah && dataSurah.ayahs.length) || document.getElementsByClassName(bodyClasses.ayah).length === 0 }
                  >
                    <ArrowBackRoundedIcon />
                  </IconButton>
                </Tooltip>
              </div>

              <div className={classes.buttonController}>
                <Tooltip title="Go to Ayah" interactive>
                  <TextField
                    value={ayahNumber}
                    onChange={e => {
                      const value = e.target.value;

                      if (value > (dataSurah && dataSurah.ayahs.length) || value < 1) {
                        setIsError(true)
                      } else {
                        clearTimeout(timeout);
                        setIsError(false);
                        setAyahNumber(value);
                        timeout = setTimeout(() => {
                          setAyah(value);
                        }, 500);
                      }

                    }}
                    variant="outlined"
                    type='number'
                    size='small'
                    error={isError}
                    inputProps={{
                      min: 1,
                      max: dataSurah ? dataSurah.ayahs.length : 1
                    }}
                    disabled={document.getElementsByClassName(bodyClasses.ayah).length === 0}
                  />
                </Tooltip>
              </div>

              <div className={classes.buttonController}>
                <Tooltip title="Previous Ayah" interactive>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={onPrevAyah}
                    disabled={ayahOrder === 1}
                  >
                    <ArrowForwardRoundedIcon />
                  </IconButton>
                </Tooltip>
              </div>
              {/* PREV AND NEXT PAGE AYAH NAVIGATION */}

              <div className={classes.buttonController}>
                <Tooltip title="Copy Ayah" interactive>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    disabled={document.getElementsByClassName(bodyClasses.ayah).length === 0}
                    onClick={() => {
                      let contents = document.getElementsByClassName(bodyClasses.ayah);
                      const copied = Array.from(contents).map(content => content.innerText);
                      
                      let holdtext = document.getElementById('holdtext');
                      holdtext.innerText = copied.join('\n');
                      holdtext.style.display = 'initial';
                      holdtext.select();
                      document.execCommand("copy");
                      holdtext.style.display = 'none';
                      setSnackbar(true);
                    }}
                  >
                    <FilterNoneRoundedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Toolbar>
            <Snackbar
              open={snackbar}
              autoHideDuration={6000}
              onClose={() => setSnackbar(false)}
              message="Text Copied"
            />
            <Snackbar
              open={isError}
              autoHideDuration={6000}
              onClose={() => setIsError(false)}
            >
              <Alert elevation={6} variant="filled" onClose={() => setIsError(false)} severity="error">
                Input Invalid
              </Alert>
            </Snackbar>
          </AppBar>
          <Toolbar />
        </>
    );
}

BottomAppBar.propTypes = {};
BottomAppBar.defaultProps = {};
 
const mapStateToProps = ({ ayahNavReducer: { ayahOrder, isTranslate }, fetchSurahReducer: { dataSurah } }) => {
  return({
    ayahOrder,
    dataSurah: dataSurah.data,
    isTranslate
  })
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onNextAyah,
      onPrevAyah,
      setAyah,
      toggleTranslation
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BottomAppBar);
