import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from 'redux/reducers';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const middlewares = [];
  // eslint-disable-next-line no-console
  console.log(
    `%c ${process.env.NODE_ENV}`,
    'font-weight: bold; font-size: 50px; color: red; text-shadow: 1px 1px 0px black, 1px -1px 0px black, -1px -1px 0px black;',
  );
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), thunk, ...middlewares)),
  );

  return store;
}
