import { GET_SURAH_LIST, GET_SURAH, GET_SURAH_TRANSLATION, SET_SURAH_FILTER } from 'redux/actions/actionTypes';

const INITIAL_STATE = {
    data: [],
    surahFilter: '',
    dataSurah:[],
    dataSurahTranslation:[],
    isLoading: false,
}

export default (state = INITIAL_STATE, action) => {
    const { type, isLoading, payload } = action;
    switch (type) {
      case GET_SURAH:
        return {...state, dataSurah: isLoading ? [] : payload, isLoading}

      case GET_SURAH_TRANSLATION:
        return {...state, dataSurahTranslation: isLoading ? [] : payload, isLoading}

      case GET_SURAH_LIST:
        return {...state, data: isLoading ? [] : payload, isLoading}

      case SET_SURAH_FILTER:
        return {
          ...state,
          surahFilter: payload,
          isLoading
        }

      default:
        return state
    }
  }
