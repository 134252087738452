import React from "react";
import PropTypes from 'prop-types';
import useStyles from './BodyContent.style';
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";

const BodyContent = ({ dataSurah, ayahOrder, dataSurahTranslation, isTranslate }) => {
    const classes = useStyles();
    const [ayah, setAyah] = React.useState('');
    const [translation, setTranslation] = React.useState('');
    const [surahOrder, setSurahOrder] = React.useState('');
    const regex = /(\u0628\u0650\u0633\u0652\u0645\u0650 \u0627\u0644\u0644\u0651\u064e\u0647\u0650 \u0627\u0644\u0631\u0651\u064e\u062d\u0652\u0645\u064e\u0670\u0646\u0650 \u0627\u0644\u0631\u0651\u064e\u062d\u0650\u064a\u0645\u0650)/g

    React.useEffect(() => {
        if((dataSurah && dataSurah.ayahs.length > 0) && (dataSurahTranslation && dataSurahTranslation.ayahs.length > 0)){
            setAyah(dataSurah.ayahs[ayahOrder-1].text);
            setTranslation(dataSurahTranslation.ayahs[ayahOrder-1].text);
            setSurahOrder(dataSurah.number);
        }
    }, [dataSurah, ayahOrder, dataSurahTranslation])

    return(
        <div className={classes.root}>
            {surahOrder > 1 && ayahOrder === 1 ? (
                <>
                    <Typography align="center" className={classes.ayah} variant='h2'>
                        {ayah.match(regex)}
                    </Typography>
                    <Typography align="justify" className={classes.ayah} variant='h2'>
                        {ayah.replace(regex, '')}
                    </Typography>
                    <br />
                    {isTranslate && (
                        <Typography align="justify" className={classes.translation} variant='h5'>
                            {translation}
                        </Typography>
                    )}
                    <textarea id="holdtext" style={{ display: 'none' }}></textarea>
                </>
            ) : (
                <>
                    <Typography align="justify" className={classes.ayah} variant='h2'>
                        {ayah}
                    </Typography>
                    <br />
                    {isTranslate && (
                        <Typography align="justify" className={classes.translation} variant='h5'>
                            {translation}
                        </Typography>
                    )}
                    <textarea id="holdtext" style={{ display: 'none' }}></textarea>
                </>
            )}
        </div>
    )
};

const mapStateToProps = ({ fetchSurahReducer: { dataSurah, dataSurahTranslation, isLoading }, ayahNavReducer: { ayahOrder, isTranslate } }) => {
    return({
        dataSurah: dataSurah.data,
        dataSurahTranslation: dataSurahTranslation.data,
        isLoading,
        ayahOrder,
        isTranslate,
    })
  };

BodyContent.propTypes = {
    ayahOrder: PropTypes.number,
    dataSurah: PropTypes.any,
    dataSurahTranslation: PropTypes.any,
};
BodyContent.defaultProps = {
    ayahOrder: 1,
    dataSurah: null,
    dataSurahTranslation: null,
};

export default connect(mapStateToProps)(BodyContent);
