import { NEXT_AYAH, PREV_AYAH, SET_AYAH, TOGGLE_TRANSLATION } from 'redux/actions/actionTypes';

const INITIAL_STATE = {
    ayahOrder: 1,
    isTranslate: true,
}

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
      case NEXT_AYAH:
        return {...state, ayahOrder: state.ayahOrder + 1}

      case PREV_AYAH:
        return {...state, ayahOrder: state.ayahOrder - 1}

      case SET_AYAH:
        return {...state, ayahOrder: Number(payload)}

      case TOGGLE_TRANSLATION:
        return {...state, isTranslate: !state.isTranslate}

    default:
        return state
    }
  }
