import { NEXT_AYAH, PREV_AYAH, SET_AYAH, TOGGLE_TRANSLATION } from './actionTypes';

export const onNextAyah = () => {
    return {
        type: NEXT_AYAH
    };
}

export const onPrevAyah = () => {
    return {
        type: PREV_AYAH
    }
}

export const setAyah = number => {
    return {
        type: SET_AYAH,
        payload: number
    }
}

export const toggleTranslation = () => {
    return {
        type: TOGGLE_TRANSLATION,
    }
}
  