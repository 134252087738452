import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => {
const { breakpoints } = theme;
return({
    root: {
        padding: 16,
        [breakpoints.up("sm")]: {
          padding: 24,
          maxWidth: 500,
          margin: "auto"
        },
        [breakpoints.up("md")]: {
          maxWidth: 700
        }
    },
    ayah: {
      fontFamily: ['Lateef', 'sans-serif'],
      direction: 'rtl'
    },
    translation: {}
  })
});

export default useStyles;
