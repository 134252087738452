import { GET_AYAH } from 'redux/actions/actionTypes';

const INITIAL_STATE = {
    dataAyah: '',
    isLoading: false,
}

export default (state = INITIAL_STATE, action) => {
    const { type, isLoading, payload } = action;
    switch (type) {
      case GET_AYAH:
        return {...state, dataAyah: isLoading ? [] : payload, isLoading}

    default:
        return state
    }
  }
