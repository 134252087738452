import Surah from 'helper/api/surah';
import { GET_SURAH, GET_SURAH_LIST, GET_SURAH_TRANSLATION } from './actionTypes';
import { request } from './request';

const surah = new Surah();

export const fetchSurah = (juz, edition) => {
    return dispatch =>
        dispatch(request(GET_SURAH, surah.getSurah(juz)));
}

export const fetchSurahTranslation = (juz, edition) => {
    return dispatch =>
        dispatch(request(GET_SURAH_TRANSLATION, surah.getSurahTranslation(juz, edition)));
}

export const fetchSurahList = () => {
    return dispatch =>
        dispatch(request(GET_SURAH_LIST, surah.getSurahList()));
}
  