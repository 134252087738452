import React from "react";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { InputBase } from "@material-ui/core";
import useStyles from "./SearchField.style";

const SearchField = ({ onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchRoundedIcon />
      </div>
      <InputBase
        placeholder="Search..."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
};

export default React.memo(SearchField);
