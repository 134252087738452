export const LOADING_PROGRESS = 'LOADING_PROGRESS';
export const LOADING_ERROR = 'LOADING_ERROR';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';

export const SET_AYAH = 'SET_AYAH';
export const NEXT_AYAH = 'NEXT_AYAH';
export const PREV_AYAH = 'PREV_AYAH';
export const TOGGLE_TRANSLATION = 'TOGGLE_TRANSLATION';

export const GET_SURAH = 'GET_SURAH'; //EQUALS TO GET AYAHS IN SURAH
export const GET_SURAH_TRANSLATION = 'GET_SURAH_TRANSLATION'; //EQUALS TO GET AYAHS IN SURAH
export const GET_SURAH_LIST = 'GET_SURAH_LIST';
export const SET_SURAH_FILTER = 'SET_SURAH_FILTER';

export const GET_AYAH = 'GET_AYAH';