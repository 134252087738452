import Ayah from 'helper/api/ayah';
import { GET_AYAH } from './actionTypes';
import { request } from './request';

const api = new Ayah();

export const fetchAyah = (surah, ayah) => {
    return dispatch =>
        dispatch(request(GET_AYAH, api.getAyah(surah, ayah)));
}
  