import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSurah, fetchSurahTranslation } from 'redux/actions/fetchSurah';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from "@material-ui/core";
import useStyles from "./SidebarList.style";
import { fetchAyah } from "redux/actions/fetchAyah";
import { setAyah } from "redux/actions/ayahNav";

const SidebarList = ({ fetchSurahTranslation, data, fetchSurah, setAyah, surahFilter }) => {
  const classes = useStyles();
  const [list, setList] = React.useState([]);
  const [selectedSurah, setSelected] = React.useState(0);

  React.useEffect(() => {
    if(data.data && data.data.length > 0){
      setList(data.data);
    }
  }, [data]);

  return (
    <List id='list-container'>
      {list
        .filter(data => {
          const surahName = data.englishName.toUpperCase();
          return surahName.indexOf(surahFilter.toUpperCase()) > -1;
        })
        .map((data, index) => (
          <ListItem
            id={`surah-${index}`}
            component="li"
            key={index}
            selected={selectedSurah === (index + 1)}
            button
            onClick={() => {
              setSelected(data.number);
              setAyah(1);
              fetchSurah(data.number)
              fetchSurahTranslation(data.number, 'id.indonesian')
            }}>
            <ListItemAvatar>
              <Avatar className={classes.avatarLetter}>{data.number}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={data.englishName}
              secondary={`${data.numberOfAyahs} Ayahs - ${data.revelationType}`}
              primaryTypographyProps={{ noWrap: true }}
            />
          </ListItem>
        ))
      }
    </List>
  );
};

SidebarList.propTypes = {
  initAyah: PropTypes.number
};
SidebarList.defaultProps = {
  initAyah: 1
};

const mapStateToProps = ({ fetchSurahReducer: { data, isLoading, surahFilter } }) => {
  return({
    data,
    surahFilter,
    isLoading,
  })
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setAyah: number => setAyah(number),
      fetchSurah: juz => fetchSurah(juz),
      fetchSurahTranslation: (juz, edition) => fetchSurahTranslation(juz, edition),
      fetchAyah: (surah, ayah) => fetchAyah(surah, ayah),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SidebarList);
