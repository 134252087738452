import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => {
  return {
    avatarLetter: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      fontSize: theme.typography.pxToRem(theme.spacing(2))
    }
  };
});

export default useStyles;
