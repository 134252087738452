import React from "react";
import * as serviceWorker from "./serviceWorker";
import Layouts from "components/Layouts";
import { Provider } from 'react-redux'
import configureStore from './redux/store'

const store = configureStore();
function App() {
  return (
    <Provider store={store}>
      <Layouts />
    </Provider>
  );
}

serviceWorker.register();

export default App;
