import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => {
    return({
        root: {
            backgroundColor: theme.palette.background.paper
        },
        label: {
            fontWeight: 900,
            minWidth: 0,
            fontSize: 18
        },
    })
});

export default useStyles;
