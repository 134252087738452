import React from 'react';
import { CssBaseline } from "@material-ui/core";
import theme from "config/theme";
import { config } from "config/layout-config";
import GlobalStyles from "config/GlobalStyles";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Root, Header, Sidebar, Content } from "@mui-treasury/layout";
import BodyContent from './BodyContent/BodyContent';
import BottomAppBar from './BottomAppBar/BottomAppBar';
import HeaderContent from './HeaderContent/HeaderContent';
import SidebarHeader from './SidebarHeader/SidebarHeader';
import SidebarList from './SidebarList/SidebarList';
import { fetchSurahList } from 'redux/actions/fetchSurah';
import useStyles from './Layouts.style';

const Index = ({ fetchSurahList }) => {
  const classes = useStyles();
  React.useEffect(() => {
    fetchSurahList();
  }, [fetchSurahList]);
    return (
        <Root theme={theme} config={config}>
        {({ headerStyles, sidebarStyles, collapsed, opened }) => (
          <>
            <CssBaseline />
            <GlobalStyles />
            <Header>
              <HeaderContent opened={opened} headerStyles={headerStyles} />
            </Header>
            <Sidebar PaperProps={{ classes: { root: classes.sidebar }}}>
              <SidebarHeader collapsed={collapsed} />
              <div className={sidebarStyles.container}>
                <SidebarList />
              </div>
            </Sidebar>
            <Content>
              <BodyContent />
            </Content>
            <BottomAppBar />
          </>
        )}
      </Root>
    );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSurahList,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Index);