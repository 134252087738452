import { combineReducers } from 'redux';
import fetchSurahReducer from './fetchSurahReducer';
import fetchAyahReducer from './fetchAyahReducer';
import ayahNavReducer from './ayahNavReducer';

export default () => combineReducers({
    fetchSurahReducer,
    fetchAyahReducer,
    ayahNavReducer,
  });
