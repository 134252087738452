import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Typography } from '@material-ui/core';
import { SidebarTrigger } from '@mui-treasury/layout';
import {
    ChevronLeftRounded as ChevronLeftRoundedIcon,
    MenuRounded as MenuRoundedIcon
} from '@material-ui/icons';
import { connect } from 'react-redux';
import useStyles from './HeaderContent.style';
 
const HeaderContent = ({ opened, headerStyles, dataSurah, ayahOrder }) => {
  const classes = useStyles();
    return (
        <Toolbar classes={{ root: classes.root }}>
              <SidebarTrigger className={headerStyles.leftTrigger}>
                {opened ? <ChevronLeftRoundedIcon /> : <MenuRoundedIcon />}
              </SidebarTrigger>
              {dataSurah && (
                <>
                  <Typography
                    noWrap
                    color="textSecondary"
                    className={classes.label}
                  >
                    {dataSurah && dataSurah.englishName}
                  </Typography>
                  <div style={{ flexGrow: 1 }} />
                  <Typography
                    noWrap
                    color="textSecondary"
                    className={classes.label}
                  >
                    {`${ayahOrder} of ${dataSurah && dataSurah.ayahs.length}`}
                  </Typography>
                </>
              )}
              
            </Toolbar>
    );
}

HeaderContent.propTypes = {
  dataSurah: PropTypes.any
};
HeaderContent.defaultProps = {
  dataSurah: null
};

const mapStateToProps = ({ ayahNavReducer: { ayahOrder }, fetchSurahReducer: { dataSurah } }) => {
  return({
    ayahOrder,
    dataSurah: dataSurah.data,
  })
};

export default connect(mapStateToProps)(HeaderContent);
