import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => {
    return({
        sidebar: {
            backgroundColor: '#fcfcf9'
        },
    })
});

export default useStyles;
