import ApiConfig from 'config/api-config';

const config = new ApiConfig();
export default class Surah {
  getSurah = juz => {
    return config.init().get(`/surah/${juz}`);
  };

  getSurahTranslation = (juz, edition) => {
    return config.init().get(`/surah/${juz}/${edition}`);
  };

  getSurahList = () => {
    return config.init().get(`/surah`);
  };
}
