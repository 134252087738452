import * as axios from 'axios';

export default class ApiConfig {
  constructor() {
    this.client = null;
    this.api_url = process.env.REACT_APP_API_URL;
  }

  init = () => {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 310000,
      headers: headers,
    });

    return this.client;
  };

}
