export const loadingError = (type, error) => (
    {
      type,
      isLoading: false,
      payload: error
    }
  )
  
  export const loadingInProgress = type => (
    {
      type,
      isLoading: true
    }
  )
  
  export const loadingSuccess = (type, response) => (
    {
      type,
      isLoading: false,
      payload: response.data
    }
  )

  export const request = (type, api) => {
    return function(dispatch) {
        dispatch(loadingInProgress(type));
        return api.then(
            (payload) => dispatch(loadingSuccess(type, payload)),
            (error) => dispatch(loadingError(type, error)),
        );
    };
  }
