import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  iconContainer: {
    padding: 16,
    transition: "0.3s",
    display: "flex"
  },
  iconContainerCollapsed: {
    padding: 8
  },
  grow: {
    flexGrow: 1
  }
}));

export default useStyles;
